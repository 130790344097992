<template>
  <div class="container">
    <div id="mainScreen" v-if="errorTag === false">
      <div class="row">
        <div class="subject col-12">{{ project_name }}投稿フォーム</div>
      </div>
      <div class="row user-name">
        <div class="col-2 user-name-text">
          ユーザー名
          <sup class="mark">※</sup>
        </div>
        <div class="col-1">
          <input
            class="user-name-input"
            type="text"
            id="user-name"
            name="user_name"
            v-model="user_name"
          />
        </div>
      </div>
      <div class="user-name-alert-block" id="user-name-alert-block">
        <p id="user-name-alert">ユーザー名を入力してください</p>
      </div>
      <div
        style="width: 300px; display: inline-block; text-align: center"
      ></div>
      <div class="row title">
        <div class="col-2 title-text">
          タイトル
          <sup class="mark">※</sup>
          <span class="title-limit" v-if="titleFlag"
            >20文字以内にしてください</span
          >
        </div>
        <div class="col-1">
          <textarea
            class="title-input"
            id="title"
            name="title"
            placeholder="20文字以内で入力してください"
            maxlength="20"
            cols="40"
            height="40"
            wrap="hard"
            v-model="title"
            @keyup="titleAlert()"
          ></textarea>
        </div>
      </div>
      <div class="title-alert-block" id="title-alert-block">
        <p id="title-alert">タイトルを入力してください</p>
      </div>
      <div class="row comment">
        <div class="col-2 comment-text">
          コメント
          <sup class="mark">※</sup>
          <span class="comment-limit" v-if="commentFlag"
            >150文字以内にしてください</span
          >
        </div>
        <div class="col-1">
          <textarea
            class="comment-input"
            id="comment"
            name="t_name"
            placeholder="150文字以内で入力してください"
            maxlength="150"
            cols="40"
            rows="5"
            v-model="comment"
            wrap="hard"
            @keyup="commentAlert()"
          ></textarea>
        </div>
      </div>
      <div class="comment-alert-block" id="comment-alert-block">
        <p id="comment-alert">コメントを入力してください</p>
      </div>
      <div class="row contents">
        <div class="col-2 contents-text">
          コンテンツ
          <sup class="mark">※</sup>
        </div>
        <div class="col-1" style="height: 40px">
          <label>
            ファイルを選択
            <input
              @change="contentsGet()"
              ref="contents"
              class="user-icon-input"
              type="file"
              id="contents"
              name="maneger"
              accept="image/*,video/*"
            />
          </label>
        </div>
        <div class="col-1 selected-file-text">{{ contentsFileName }}</div>
      </div>
      <div class="contents-alert-block" id="contents-alert-block">
        <p id="contents-alert">コンテンツを入力してください</p>
      </div>
      <div class="row">
        <div class="col-2"></div>
        <div class="col-1">
          <button
            class="reserve-button"
            id="post-button"
            type="submit"
            v-on:click="postForm()"
          >
            投稿する
          </button>
        </div>
      </div>
      <vue-loading
        v-if="loading"
        id="loading"
        type="spin"
        color="black"
        :size="{ width: '50px', height: '50px' }"
      >
      </vue-loading>
      <video
        hidden
        id="video"
        width="780"
        height="1280"
        controls="false"
        @loadeddata="getVideoAspect"
      ></video>
      <canvas id="mycanvas"></canvas>
    </div>
    <div class="outer">
      <div class="error-screen" v-if="errorTag === true">
        ご契約プランの動画アップロード限界値を超えています、プランをアップグレードするか、不要な動画を削除して下さい。
      </div>
      <div class="error-screen" v-if="errorTag === true">（DBエラー）</div>
    </div>
    <div id="fadeLayer"></div>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/vue-js-modal@1.3.31/dist/index.min.js"></script> 
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
const tus = require("tus-js-client");
const Vimeo = require("vimeo").Vimeo;
//練習用
var VIMEO_CLIENT_ID = process.env.VUE_APP_VIMEO_CLIENT_ID;
var VIMEO_CLIENT_SECRET = process.env.VUE_APP_VIMEO_CLIENT_SECRET;
var VIMEO_ACCESS_TOKEN = process.env.VUE_APP_VIMEO_ACCESS_TOKEN;

/* 事前に用意したID/秘密鍵/アクセストークンを設定してください */
const client = new Vimeo(
  VIMEO_CLIENT_ID, // ID
  VIMEO_CLIENT_SECRET, // 秘密鍵
  VIMEO_ACCESS_TOKEN // アクセストークン
);
import { VueLoading } from "vue-loading-template";
var video = document.querySelector("video");

// @ is an alias to /src
export default {
  name: "ClientRegisterComponent",
  components: {
    VueLoading,
  },
  data: function () {
    return {
      titleFlag: false,
      commentFlag: false,
      loading: false,
      client_id: "", //クライアントID
      project_id: "", //プロジェクトID
      user_id: "", //ユーザーID
      user_name: "", //ユーザー名
      title: "", //タイトル
      comment: "", //コメント
      post_date: "",
      contentsFileName: "選択されていません", //コンテンツのファイル名
      contentsType: "", //コンテンツタイプ
      video_id: "",
      folder_id: "",
      clientInfo: [], //クライアント情報
      projectInfo: [], //プロジェクト情報
      project_name: "",
      contentsSize: "", //選択されているファイルの容量
      errorTag: false,
      uploadDBChangeToMB: "",
      loginEmail: "",
      loginPassword: "",
    };
  },
  mounted() {
    video = document.querySelector("video");
    var instance = this;
    video.addEventListener("seeked", function () {
      var type = "image/jpeg";
      image.setAttribute("href", instance.toDataURL(video));
    });
    this.client_id = this.getParam("hgisr");
    this.project_id = this.getParam("shrkghkkgj");
    this.login();
    this.getClientInfo();
  },
  beforeDestroy() {},
  watch: {
    title: function () {
      var title = document.getElementById("title");
      if (title.value.length > 20) {
        this.titleFlag = true;
      } else {
        this.titleFlag = false;
      }
    },
    comment: function () {
      var comment = document.getElementById("comment");
      if (comment.value.length > 150) {
        this.commentFlag = true;
      } else {
        this.commentFlag = false;
      }
    },
  },
  methods: {
    titleAlert: function () {
      var title = document.getElementById("title");
      if (title.value.length > 20) {
        this.titleFlag = true;
      } else {
        this.titleFlag = false;
      }
    },
    commentAlert: function () {
      var title = document.getElementById("comment");
      if (title.value.length > 150) {
        this.commentFlag = true;
      } else {
        this.commentFlag = false;
      }
    },
    /////////////////////////////////////////////////////
    /////////////////////////////////////
    /////////////説明
    ///////////ログインする
    ///
    ///
    ///
    ////////
    ///////////
    /////////////////
    /////////////////////////引数：なし
    /////////////////////////////////////////////////////////////////////////////
    login: async function () {
      await this.getPass();
    },
    /////////////////////////////////////////////////////
    /////////////////////////////////////
    /////////////説明
    ///////////uploadアカウントにログインし、firebase storageにアップロード出来る状態にする
    ///
    ///
    ///
    ////////
    ///////////
    /////////////////
    /////////////////////////引数：なし
    /////////////////////////////////////////////////////////////////////////////
    firebaseUploadAccountLogin() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.loginEmail, this.loginPassword)
        .then(() => {
          this.getClientInfo();
        });
    },
    /////////////////////////////////////////////////////
    /////////////////////////////////////
    /////////////説明
    ///////////アカウントのパスを取得する
    ///
    ///
    ///
    ////////
    ///////////
    /////////////////
    /////////////////////////引数：なし
    /////////////////////////////////////////////////////////////////////////////
    getPass: async function () {
      var instance = this;
      await firebase
        .storage()
        .ref("/uploadAccount/uploadInfo.json")
        .getDownloadURL()
        .then(async (url) => {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url);
          xhr.onload = function (event) {
            var blob = xhr.response;
            var account = JSON.parse(blob);
            instance.loginEmail = account["email"];
            instance.loginPassword = account["pass"];
            instance.firebaseUploadAccountLogin();
          };
          xhr.send();
        });
    },
    /////////////////////////////////////////////////////
    /////////////////////////////////////
    /////////////説明
    ///////////storageからクライアント及びプロジェクトの情報を取得する
    ///
    ///
    ///
    ////////
    ///////////
    /////////////////
    /////////////////////////引数：なし
    /////////////////////////////////////////////////////////////////////////////
    getClientInfo: async function () {
      var instance = this;
      var storage = firebase.storage();
      var storageRef = storage.ref("/db/" + this.client_id + ".json");
      storageRef
        .getDownloadURL()
        .then((url) => {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url);
          xhr.onload = function (event) {
            var blob = xhr.response;
            instance.clientInfo.push(JSON.parse(blob));
          };
          xhr.send();
        })
        .catch(function (err) {
          console.log();
        })
        .then((response) => {
          // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
          const data = response;
        })
        .catch(function (err) {
          console.log();
        });

      var userRef = storage.ref(
        "/db/" + this.client_id + "/" + this.project_id + ".json"
      );

      userRef
        .getDownloadURL()
        .then((url) => {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url);
          xhr.onload = function (event) {
            var blob = xhr.response;
            instance.projectInfo.push(JSON.parse(blob));
            instance.project_name = instance.projectInfo[0].project_name;
            instance.folder_id = instance.projectInfo[0].vimeo_folder_pass;
          };
          xhr.send();
        })
        .then((response) => {
          // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
          const data = response;
        });
    },
    /////////////////////////////////////////////////////
    /////////////////////////////////////
    /////////////説明
    ///////////投稿処理
    ///
    ///
    ///
    ////////
    ///////////
    /////////////////
    /////////////////////////引数：なし
    /////////////////////////////////////////////////////////////////////////////
    postForm: function () {
      var userNameNode = document.getElementById("user-name-alert");
      var userNameblockNode = document.getElementById("user-name-alert-block");
      var titleNode = document.getElementById("title-alert");
      var titleBlockNode = document.getElementById("title-alert-block");
      var commentNode = document.getElementById("comment-alert");
      var commentBlockNode = document.getElementById("comment-alert-block");
      var contentsFile = this.$refs.contents.files;
      var contentsNode = document.getElementById("contents-alert");
      var contentsFileBlock = document.getElementById("contents-alert-block");
      if (
        this.user_name != "" &&
        this.title != "" &&
        this.comment != "" &&
        contentsFile.length != 0
      ) {
        //必須項目を全て入力出来ていたら
        userNameNode.style.display = "none";
        userNameblockNode.style.display = "none";
        titleNode.style.display = "none";
        titleBlockNode.style.display = "none";
        commentNode.style.display = "none";
        commentBlockNode.style.display = "none";
        contentsNode.style.display = "none";
        contentsFileBlock.style.display = "none";

        ////////////アップロードできるかどうかのチェック
        var userdDB = this.clientInfo[0].used_db; //使用している使用DB

        var dbPlan = this.clientInfo[0].contract_plan; //契約プラン

        this.uploadDBChangeToMB = this.contentsSize / 1048576; //アップロードするファイルのバイト数をMBに変換

        if (userdDB + this.uploadDBChangeToMB > dbPlan) {
          //アップロードすることで契約プランのDBを超えてしまった場合
          this.errorTag = true;
        } else {
          //投稿ボタンを押せないようにする。
          document.getElementById("post-button").setAttribute("disabled", true);

          //ローディング表示
          this.loading = true;

          document.getElementById("fadeLayer").style.visibility = "visible";

          //ユーザーIDをデータに登録する
          this.getUniqueStr();

          //投稿日をデータに登録する
          this.formatDate();

          //vimeoに動画を保存する
          if (this.contentsType == "動画") {
            this.vimeoRegister();
            //this.reserveStorage("null");
          } else if (this.contentsType == "画像") {
            this.reserveStorage("null");
          }
          //プロジェクト情報を保存
          this.reserveProjectInfo();

          //クライアント情報を保存
          this.reserveUsedDBClient();
        }
      } else {
        //未入力項目があった場合
        if (this.user_name == "") {
          userNameNode.style.display = "block";
          userNameblockNode.style.display = "block";
        }
        if (this.title == "") {
          titleNode.style.display = "block";
          titleBlockNode.style.display = "block";
        }
        if (this.comment == "") {
          commentNode.style.display = "block";
          commentBlockNode.style.display = "block";
        }
        if (contentsFile.length == 0) {
          contentsNode.style.display = "block";
          contentsFileBlock.style.display = "block";
        }
      }
    },
    /////////////////////////////////////////////////////
    /////////////////////////////////////
    /////////////説明
    ///////////コンテンツのファイルを選択した際の処理
    ///
    ///
    ///
    ////////
    ///////////
    /////////////////
    /////////////////////////引数：なし
    /////////////////////////////////////////////////////////////////////////////
    contentsGet: function () {
      var fileName = this.$refs.contents.files[0].name;
      this.contentsFileName = fileName;
      this.contentsType = this.$refs.contents.files[0].type;
      this.contentsSize = this.$refs.contents.files[0].size;

      //コンテンツのタイプをデータに登録する
      this.checkContentsType();

      if (this.contentsType == "動画") {
        //サムネイルの切り出し
        this.selectedReadFile();
        //this.getThumbnail(this.$refs.contents.files[0]);
      }
    },
    //////////////////////////////////////////////
    ////////説明：ユーザーIDを自動生成する
    /////
    /////
    /////
    /////引数:myStrong:実際は何も入らない
    /////////////////////////////////////////////////////////
    getUniqueStr: function (myStrong) {
      //クライアントIDを生成する
      var strong = 1000;
      if (myStrong) strong = myStrong;
      this.user_id =
        new Date().getTime().toString(16) +
        Math.floor(strong * Math.random()).toString(16);
    },
    //////////////////////////////////////////////
    ////////説明：クエリパラメータを取得
    /////
    /////
    /////
    /////引数:name:クエリ名 url:url
    /////////////////////////////////////////////////////////
    getParam(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    //////////////////////////////////////////////
    ////////説明：選択されたファイルを読み込む
    /////
    /////
    /////
    /////引数：なし
    /////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////ここからサムネイルの切り出し///////////////////////////
    selectedReadFile: function () {
      var file = this.$refs.contents.files[0];
      var instance = this;
      var reader = new FileReader();
      //dataURL形式でファイルを読み込む
      reader.readAsDataURL(file);
      //ファイルの読込が終了した時の処理
      reader.onload = function () {
        var canvas = document.getElementById("mycanvas");
        instance.readDrawImg(reader, canvas, 0, 0);
      };
    },
    readDrawImg: function (reader, canvas, x, y) {
      var img = this.readImg(reader);
      var instace = this;
      img.onload = function () {
        var w = img.width;
        var h = img.height;
        instace.printWidthHeight("src-width-height", true, w, h);
        // モバイルであればリサイズ
        var resize = instace.resizeWidthHeight(1024, w, h);
        instace.printWidthHeight(
          "dst-width-height",
          resize.flag,
          resize.w,
          resize.h
        );
        instace.drawImgOnCav(canvas, img, x, y, w, h);
      };
    },
    readImg: function (reader) {
      //ファイル読み取り後の処理
      var result_dataURL = reader.result;
      var img = new Image();
      img.src = result_dataURL;
      return img;
    },
    drawImgOnCav: function (canvas, img, x, y, w, h) {
      var ctx = canvas.getContext("2d");
      canvas.width = w;
      canvas.height = h;
      ctx.drawImage(img, x, y, w, h);
    },
    resizeWidthHeight: function (target_length_px, w0, h0) {
      //リサイズの必要がなければ元のwidth, heightを返す
      var length = Math.max(w0, h0);
      if (length <= target_length_px) {
        return {
          flag: false,
          w: w0,
          h: h0,
        };
      }
      //リサイズの計算
      var w1;
      var h1;
      if (w0 >= h0) {
        w1 = target_length_px;
        h1 = (h0 * target_length_px) / w0;
      } else {
        w1 = (w0 * target_length_px) / h0;
        h1 = target_length_px;
      }
      return {
        flag: true,
        w: parseInt(w1),
        h: parseInt(h1),
      };
    },
    printWidthHeight(width_height_id, flag, w, h) {
      var wh = document.getElementById(width_height_id);
      console.log(wh);
    },
    /////////////////////////////////////////////////////////////////////////////////ここまでサムネイルの切り出し処理//////////////////
    //////////////////////////////////////////////
    ////////説明：画像/動画の判別をする
    /////
    /////
    /////
    /////引数：なし
    /////////////////////////////////////////////////////////
    checkContentsType: function () {
      if (this.contentsType.indexOf("image") == -1) {
        //動画だった場合
        this.contentsType = "動画";
      } else if (this.contentsType.indexOf("video") == -1) {
        //画像だった場合
        this.contentsType = "画像";
      } else {
        //画像・動画以外だった場合
        this.contentsType = "";
      }
    },
    //////////////////////////////////////////////
    ////////説明：投稿日を取得する
    /////
    /////
    /////
    /////引数：なし
    /////////////////////////////////////////////////////////
    formatDate: function () {
      var dt = new Date();
      var y = dt.getFullYear();
      var m = ("00" + (dt.getMonth() + 1)).slice(-2);
      var d = ("00" + dt.getDate()).slice(-2);
      this.post_date = y + "/" + m + "/" + d;
    },
    //////////////////////////////////////////////
    ////////説明：サムネイルを取得する
    /////
    /////
    /////
    /////引数：なし
    /////////////////////////////////////////////////////////
    getThumbnail: function (object) {
      video.src = URL.createObjectURL(object);
      video.currentTime = 1;
      image.setAttribute("href", this.toDataURL(video));
    },
    //////////////////////////////////////////////
    ////////説明：動画のアスペクト比を取得する
    /////
    /////
    /////
    /////引数：なし
    /////////////////////////////////////////////////////////
    getVideoAspect: function () {
      //取得したいアスペクト比の動画エレメントを取得
      var videoElement = document.getElementById("video");
      var videoWidth = videoElement.videoWidth;
      var videoHeight = videoElement.videoHeight;

      this.videoWidth = videoWidth;
      this.videoHeight = videoHeight;

      image.setAttribute(
        "href",
        this.toDataURL(video, videoWidth, videoHeight)
      );
    },
    //////////////////////////////////////////////
    ////////説明：vimeoに動画を保存する処理
    /////
    /////
    /////
    /////引数：なし
    /////////////////////////////////////////////////////////
    vimeoRegister: function () {
      const file = this.$refs.contents.files[0];
      const folder_id = this.folder_id;
      var video_uri;
      var instanceThis = this;
      client.request(
        {
          method: "POST",
          path: "/me/videos",
          query: {
            file_name: file.name,
            upload: {
              status: "in_progress",
              size: file.size,
              approach: "tus",
            },
            privacy: { view: "disable" },
          },
        },
        function (error, body, status_code, headers) {
          const options = {
            endpoint: "none",
            retryDelays: [0, 3000, 5000, 10000, 20000],
            onError: function (error) {},
            onProgress: function (bytesUploaded, bytesTotal) {},
            onSuccess: function () {
              //ストレージに保存
              //instanceThis.reserveStorage(hash);
            },
          };

          const upload = new tus.Upload(file, options);
          upload.url = body.upload.upload_link;
          upload.start();

          //videoのIDのみ抜き取る
          var videoId = body.uri.replace("/videos/", "");

          video_uri = body.uri;
          var iframeURL = body.embed.html; //埋め込みifrmaeタグ
          var idstart;
          var idgoal;
          var hash;
          //文字列検索で?h=となっている文字列位置を検索し、その位置数を代入
          idstart = iframeURL.indexOf("video/");

          //文字列検索で&amp=となっている文字列位置を検索し、その位置数を代入
          idgoal = iframeURL.indexOf("&amp;");

          //文字列を切り出す
          hash = iframeURL.substring(idstart + 6, idgoal);

          //ストレージに保存
          instanceThis.reserveStorage(hash);
          //////////////////////////////////////ホワイトリストに登録する
          client.request(
            {
              method: "PATCH",
              path: body.uri,
              query: {
                privacy: {
                  embed: "whitelist",
                },
              },
            },
            function (error, body, status_code, headers) {
              /*
              client.request(
                {
                  method: "PUT",
                  path:
                    body.uri +
                    "/privacy/domains/" +
                    "iframe-short-video.firebaseapp.com" +
                    "?privacy.embed=whitelist",
                },
                function (error, body, status_code, headers) {}
              );
              client.request(
                {
                  method: "PUT",
                  path:
                    body.uri +
                    "/privacy/domains/client-short-video.firebaseapp.com/?privacy.embed=whitelist",
                },
                function (error, body, status_code, headers) {}
              );
              */
              client.request(
                {
                  method: "PUT",
                  path:
                    body.uri +
                    // "/privacy/domains/client-3e3c0.web.app?privacy.embed=whitelist",//テスト用
                    "/privacy/domains/client.short-video.jp?privacy.embed=whitelist", //本番用
                },
                function (error, body, status_code, headers) {}
              );
              client.request(
                {
                  method: "PUT",
                  path:
                    body.uri +
                    //"/privacy/domains/iframe-93448.web.app?privacy.embed=whitelist",//テスト用
                    "/privacy/domains/short-video.jp?privacy.embed=whitelist", //本番用
                },
                function (error, body, status_code, headers) {}
              );
            }
          );
          //////////////////特定フォルダに動画をアップロードする処理///////////////////////
          client.request(
            {
              method: "PUT",
              path: folder_id + video_uri,
            },
            function (error, body, status_code, headers) {
              const options = {
                endpoint: "none",
                retryDelays: [0, 3000, 5000, 10000, 20000],
                onError: function (error) {
                  console.log(error);
                },
                onProgress: function (bytesUploaded, bytesTotal) {},
                onSuccess: function () {},
              };
            }
          );
        }
      );
    },
    //////////////////////////////////////////////
    ////////説明：画像名を自動生成する
    /////
    /////
    /////
    /////引数:myStrong:実際は何も入らない
    /////////////////////////////////////////////////////////
    getUniqueStrImageUrl: function (myStrong) {
      //クライアントIDを生成する
      var strong = 1000;
      if (myStrong) strong = myStrong;
      this.contentsFileName =
        new Date().getTime().toString(16) +
        Math.floor(strong * Math.random()).toString(16);
    },
    //////////////////////////////////////////////
    ////////説明：ストレージにユーザー情報を保存する
    /////
    /////
    /////
    /////引数：なし
    /////////////////////////////////////////////////////////
    reserveStorage(hash) {
      this.video_id = hash;
      var instance = this;
      var data = {
        post_date: this.post_date,
        user_id: this.user_id,
        user_name: this.user_name,
        title: this.title,
        comment: this.comment,
        approval_status: "0",
        contents_kinds: this.contentsType,
        main_image_url: "",
        video_id: "",
        thumb_image_url: "",
        user_name_status: "1",
        title_status: "1",
        comment_status: "1",
        cta_status: "1",
        cta: "",
        thumb_status: "1",
        main_thumb_image_url: "",
        order: "",
        uploadDBChangeToMB: this.uploadDBChangeToMB,
        content_fileName: this.contentsFileName,
      };
      if (this.contentsType == "動画") {
        var canvas = document.getElementById("mycanvas");
        console.log(canvas.toDataURL());
        //var uri = this.toDataURL(video);
        var hash = this.video_id;
        // "iVBORw..."をバイナリに変換
        var byteString = atob(canvas.toDataURL().split(",")[1]);
        var buffer = new Uint8Array(byteString.length);
        // Uint8Array ビューに 1 バイトずつ値を埋める
        for (var i = 0; i < byteString.length; i++) {
          buffer[i] = byteString.charCodeAt(i);
        }
        var type = "image/jpeg";

        // Uint8Array ビューのバッファーを抜き出し、それを元に Blob を作る
        var blob = new Blob([buffer.buffer], { type: type });

        //画像のファイル名を決める
        this.getUniqueStrImageUrl();

        data.content_fileName = this.contentsFileName;

        var ref = firebase
          .storage()
          .ref()
          .child(
            "img/" +
              this.client_id +
              "/" +
              this.project_id +
              "/" +
              this.contentsFileName
          );
        const userRef = firebase
          .storage()
          .ref()
          .child(
            "/user/" +
              this.client_id +
              "/" +
              this.project_id +
              "/" +
              this.user_id +
              ".json"
          );
        ref.put(blob).then(function (snapshot) {
          snapshot.ref.getDownloadURL().then((url) => {
            data.main_thumb_image_url = url;
            data.video_id = hash;
            //ユーザー情報
            const jsonString = JSON.stringify(data);

            const blob = new Blob([jsonString], { type: "application/json" });
            userRef.put(blob).then(function () {
              //初期化
              instance.sucessUpload();
            });
          });
        });
      } else if (this.contentsType == "画像") {
        this.getUniqueStrImageUrl();
        data.content_fileName = this.contentsFileName;
        var ref = firebase
          .storage()
          .ref()
          .child(
            "img/" +
              this.client_id +
              "/" +
              this.project_id +
              "/" +
              this.contentsFileName
          );
        const userRef = firebase
          .storage()
          .ref()
          .child(
            "/user/" +
              this.client_id +
              "/" +
              this.project_id +
              "/" +
              this.user_id +
              ".json"
          );
        var image = this.$refs.contents.files[0];
        ref.put(image).then(function (snapshot) {
          snapshot.ref.getDownloadURL().then((url) => {
            data.main_image_url = url;
            //ユーザー情報
            const jsonString = JSON.stringify(data);

            const blob = new Blob([jsonString], { type: "application/json" });
            userRef.put(blob).then(function () {
              //初期化
              instance.sucessUpload();
            });
          });
        });
      }
    },
    //////////////////////////////////////////////
    ////////説明：ストレージにプロジェクト情報を保存する
    /////
    /////
    /////
    /////引数：なし
    /////////////////////////////////////////////////////////
    reserveProjectInfo: function () {
      var instance = this;
      var ref = firebase
        .storage()
        .ref()
        .child("db/" + this.client_id + "/" + this.project_id + ".json");

      var project = this.projectInfo;

      //もともとプロジェクトに登録されているDB容量
      var beforeContentsData = Number(project[0].used_data_contents);

      //アップロードする容量
      var uploadData = Math.ceil(Number(this.uploadDBChangeToMB));

      //合計使用量DB
      var newData = beforeContentsData + uploadData;

      project[0].used_data_contents = newData;

      ///////////新しく追加2022/04/21/////////////////////
      var uploadNumber = 1;
      project[0].used_number += uploadNumber;

      //プロジェクト情報
      const jsonString = JSON.stringify(project[0]);

      const blob = new Blob([jsonString], { type: "application/json" });
      ref.put(blob).then(function () {});
    },
    //////////////////////////////////////////////
    ////////説明：Firebase Storageにクライアントの使用量DBを保存する
    /////
    /////
    /////
    /////引数：なし
    /////////////////////////////////////////////////////////
    reserveUsedDBClient: function () {
      var ref = firebase
        .storage()
        .ref()
        .child("db/" + this.client_id + ".json");

      var client = this.clientInfo;

      //もともとクライアントに登録されているDB容量
      var beforeContentsData = Number(client[0].used_db);

      //アップロードする容量
      var uploadData = Math.ceil(Number(this.uploadDBChangeToMB));

      //合計使用量DB
      var newData = beforeContentsData + uploadData;

      client[0].used_db = newData;

      ///////////新しく追加2022/04/21/////////////////////
      var uploadNumber = 1;
      client[0].used_number += uploadNumber;

      //プロジェクト情報
      const jsonString = JSON.stringify(client[0]);

      const blob = new Blob([jsonString], { type: "application/json" });
      ref.put(blob).then(function () {});
    },
    //////////////////////////////////////////////
    ////////説明：動画のアップロードが完了した際の後処理
    /////
    /////
    /////
    /////引数：なし
    /////////////////////////////////////////////////////////
    sucessUpload: function () {
      /////////////////////////すべて初期化//////////////////////////
      this.user_name = "";
      this.title = "";
      this.comment = "";
      this.contentsFileName = "選択されていません";
      this.video_id = "";
      ///////////////////////////アップロードのお知らせ///////////////////////
      //ボタンの非活性を解く
      document.getElementById("post-button").removeAttribute("disabled");
      document.getElementById("fadeLayer").style.visibility = "hidden";
      this.loading = false;
      alert("アップロードしました");
      location.reload();
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap");
#mycanvas {
  border: 1px #333 solid;
  max-width: 100%;
  height: auto;
  visibility: hidden;
  position: absolute;
  top: -9999px;
}

.title-limit {
  font-size: 12px;
  color: red;
}

.comment-limit {
  font-size: 12px;
  color: red;
}

#fadeLayer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  visibility: hidden;
  z-index: 1;
}

#loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 99999999;
}

.outer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.error-screen {
  font-size: 24px;
  color: #272727;
  opacity: 1;
}
@media screen and (orientation: portrait) {
  /* 縦向きの場合のスタイル */
  #image {
    visibility: hidden;
    position: absolute;
    top: -9999px;
  }
  #svg {
    visibility: hidden;
    position: absolute;
    top: -9999px;
  }

  .container {
    margin-top: 5%;
  }

  .subject {
    font-size: 25px;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    margin-top: 20%;
    margin-left: 0%;
    color: #4a4a4a;
    opacity: 1;
  }

  /*ここからは各項目のブロック単位での設定 */
  .user-name {
    margin-top: 10%;
    display: inline-block;
    width: 300px;
    text-align: center;
  }

  .title {
    margin-top: 10%;
    display: inline-block;
    width: 300px;
    text-align: center;
  }

  .comment {
    margin-top: 10%;
    display: inline-block;
    width: 300px;
    text-align: center;
  }

  .user-icon-contents {
    margin-top: 10%;
    display: inline-block;
    width: 300px;
    text-align: center;
  }

  .contents {
    margin-top: 10%;
    display: inline-block;
    width: 300px;
    text-align: center;
  }
  /**------------------------------------------------------- */

  /*ここからはテキストの文字の大きさ */
  .user-name-text {
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
  }

  .user-icon {
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
  }

  .title-text {
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
  }

  .comment-text {
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
  }

  .contents-text {
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
  }
  /**------------------------------------------------------- */

  /*ここからはinputタグの設定 */
  .user-name-input {
    width: 300px;
    height: 40px;
    border: 0.5px solid #343434;
    border-radius: 4px;
    opacity: 1;
  }

  .user-icon-input {
    display: none;
    background-color: white;
    letter-spacing: 0px;
    color: #a1a1a1;
    width: 300px;
    height: 40px;
  }

  label {
    width: 128px;
    height: 35px;
    background: #e0e1e4 0% 0% no-repeat padding-box;
    padding-top: 10px;
    border: 0.5px solid #343434;
    border-radius: 4px 0px 0px 4px;
    opacity: 1;
  }

  .selected-file-text {
    color: #a1a1a1;
    padding-top: 10px;
    margin-left: 130px;
    margin-top: -40px;
    background-color: white;
    height: 35px;
    border: 0.5px solid #343434;
    border-left: none;
    border-radius: 0px 4px 0px 0px;
    opacity: 1;
    overflow-wrap: break-word;
    overflow: hidden;
  }

  .title-input {
    width: 300px;
    height: 40px;
    border: 0.5px solid #343434;
    border-radius: 4px;
    opacity: 1;
  }

  .comment-input {
    width: 300px;
    height: 80px;
    border: 0.5px solid #343434;
    border-radius: 4px;
    opacity: 1;
  }

  .contents-input {
    letter-spacing: 0px;
    color: #a1a1a1;
    width: 143px;
    height: 24px;
  }

  /**------------------------------------------------------- */

  .event_priod {
    margin-top: 3%;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 16px;
  }

  .icon-image {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 80px 80px 80px 80px;
    text-align: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #343434;
    opacity: 1;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  label {
    display: block;
  }

  span {
    margin-left: 7%;
  }

  .reserve-button {
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    margin-top: 10%;
    margin-bottom: 20%;
    width: 300px;
    height: 60px;
    background: transparent linear-gradient(180deg, #ff9700 0%, #ff6700 100%) 0%
      0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    color: #ffffff;
    font-size: 24px;
  }

  .reserve-text {
    text-align: center;
    font-size: 18px;
  }

  .text-content {
    margin-top: 10%;
  }

  .mark {
    background: #f7f2ee 0% 0% no-repeat padding-box;
    display: inline;
    color: red;
  }

  /*********************アラートに関数記述 ***************************/

  #user-name-alert {
    margin-top: 0%;
    color: red;
    font-size: 10px;
    display: none;
    text-align: left;
    margin-left: 35px;
  }

  .user-name-alert-block {
    display: inline-block;
    width: 300px;
    text-align: center;
  }

  #title-alert {
    text-align: left;
    margin-top: 0%;
    margin-left: 35px;
    color: red;
    font-size: 10px;
    display: none;
  }

  .title-alert-block {
    width: 300px;
    display: inline-block;
    text-align: center;
    display: none;
  }

  #comment-alert {
    margin-top: 0%;
    color: red;
    font-size: 10px;
    display: none;
    margin-left: 35px;
    text-align: left;
  }

  .comment-alert-block {
    width: 300px;
    display: inline-block;
    text-align: center;
    display: none;
  }

  #contents-alert {
    margin-top: 0%;
    color: red;
    font-size: 10px;
    display: none;
    margin-left: 35px;
    text-align: left;
  }

  .contents-alert-block {
    margin-top: 0%;
    color: red;
    font-size: 10px;
    display: none;
  }
}

@media screen and (orientation: landscape) {
  /* 横向きの場合のスタイル */
  #image {
    visibility: hidden;
    position: absolute;
    top: -9999px;
  }
  #svg {
    visibility: hidden;
    position: absolute;
    top: -9999px;
  }

  .container {
    margin-top: 5%;
  }

  .subject {
    font-size: 25px;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    margin-top: 20%;
    margin-left: 0%;
    color: #4a4a4a;
    opacity: 1;
  }

  /*ここからは各項目のブロック単位での設定 */
  .user-name {
    margin-top: 10%;
    margin-right: auto;
    margin-left: auto;
    width: 300px;
    text-align: center;
  }

  .title {
    margin-top: 10%;
    margin-right: auto;
    margin-left: auto;
    width: 300px;
    text-align: center;
  }

  .comment {
    margin-top: 10%;
    margin-right: auto;
    margin-left: auto;
    width: 300px;
    text-align: center;
  }

  .user-icon-contents {
    margin-top: 10%;
    margin-right: auto;
    margin-left: auto;
    width: 300px;
    text-align: center;
  }

  .contents {
    margin-top: 10%;
    margin-right: auto;
    margin-left: auto;
    width: 300px;
    text-align: center;
  }
  /**------------------------------------------------------- */

  /*ここからはテキストの文字の大きさ */
  .user-name-text {
    font-size: 18px;
    text-align: left;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
  }

  .user-icon {
    font-size: 18px;
    text-align: left;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
  }

  .title-text {
    font-size: 18px;
    text-align: left;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
  }

  .comment-text {
    font-size: 18px;
    text-align: left;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
  }

  .contents-text {
    font-size: 18px;
    text-align: left;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
  }
  /**------------------------------------------------------- */

  /*ここからはinputタグの設定 */
  .user-name-input {
    width: 300px;
    height: 40px;
    border: 0.5px solid #343434;
    border-radius: 4px;
    opacity: 1;
  }

  .user-icon-input {
    display: none;
    background-color: white;
    letter-spacing: 0px;
    color: #a1a1a1;
    width: 300px;
    height: 40px;
  }

  label {
    width: 128px;
    height: 35px;
    background: #e0e1e4 0% 0% no-repeat padding-box;
    padding-top: 10px;
    border: 0.5px solid #343434;
    border-radius: 4px 0px 0px 4px;
    opacity: 1;
  }

  .selected-file-text {
    color: #a1a1a1;
    padding-top: 10px;
    margin-left: 130px;
    margin-top: -40px;
    background-color: white;
    height: 35px;
    border: 0.5px solid #343434;
    border-left: none;
    border-radius: 0px 4px 0px 0px;
    opacity: 1;
    overflow-wrap: break-word;
    overflow: hidden;
  }

  .title-input {
    width: 300px;
    height: 40px;
    border: 0.5px solid #343434;
    border-radius: 4px;
    opacity: 1;
  }

  .comment-input {
    width: 300px;
    height: 80px;
    border: 0.5px solid #343434;
    border-radius: 4px;
    opacity: 1;
  }

  .contents-input {
    letter-spacing: 0px;
    color: #a1a1a1;
    width: 143px;
    height: 24px;
  }

  /**------------------------------------------------------- */

  .event_priod {
    margin-top: 3%;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 16px;
  }

  .icon-image {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 80px 80px 80px 80px;
    text-align: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #343434;
    opacity: 1;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  label {
    display: block;
  }

  span {
    margin-left: 7%;
  }

  .reserve-button {
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    margin-top: 10%;
    margin-bottom: 20%;
    width: 300px;
    height: 60px;
    background: transparent linear-gradient(180deg, #ff9700 0%, #ff6700 100%) 0%
      0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    color: #ffffff;
    font-size: 24px;
  }

  .reserve-text {
    text-align: center;
    font-size: 18px;
  }

  .text-content {
    margin-top: 10%;
  }

  .mark {
    background: #f7f2ee 0% 0% no-repeat padding-box;
    display: inline;
    color: red;
  }

  /*********************アラートに関数記述 ***************************/
  .user-name-alert-block {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  #user-name-alert {
    margin-top: 0%;
    color: red;
    font-size: 10px;
    display: none;
    text-align: left;
  }

  #title-alert {
    text-align: left;
    margin-top: 0%;
    color: red;
    font-size: 10px;
    display: none;
  }

  .title-alert-block {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  #comment-alert {
    margin-top: 0%;
    color: red;
    font-size: 10px;
    display: none;
    text-align: left;
  }

  .comment-alert-block {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  #contents-alert {
    margin-top: 0%;
    color: red;
    font-size: 10px;
    display: none;
    text-align: left;
  }

  .contents-alert-block {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
</style>
