<template>
  <div class="home">
    <PostForm v-if="deviceIphone == false" />
    <IphonePostForm v-if="deviceIphone == true" />
  </div>
</template>

<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
import PostForm from "@/components/PostForm.vue";
import IphonePostForm from "@/components/IphonePostForm.vue";
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};
const app = firebase.initializeApp(firebaseConfig);

export default {
  name: "Home",
  components: {
    PostForm,
    IphonePostForm,
  },
  mounted: function () {
    const userAgent = window.navigator.userAgent;

    //iphoneかそれ以外か
    if (userAgent.indexOf("iPhone") > 0) {
      this.deviceIphone = true;
    }
  },
  data: function () {
    return {
      deviceIphone: false, //デバイスがiPhoneだとtrue
    };
  },
};
</script>
